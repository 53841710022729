<template>
  <div class="container_con">
    <el-card>
      <div class="con_title"><span></span> 新增货位</div>
      <el-form ref="form" :rules="rules" :model="form" label-width="120px">
        <el-form-item label="货位编号：" prop="cargo_space_no">
            <el-input v-model="form.cargo_space_no" placeholder="请输入货位编号" style="width:300px" />
        </el-form-item>
        <el-form-item label="货位名称：" prop="cargo_space_name">
            <el-input v-model="form.cargo_space_name" placeholder="请输入货位名称" style="width:300px" />
        </el-form-item>
        <el-form-item label="架位号：" prop="rack_position_number">
            <el-input v-model="form.rack_position_number" placeholder="请输入架位号" style="width:300px" />
        </el-form-item>
        <el-form-item label="排序：">
            <el-input-number v-model="form.sort" controls-position="right" :min="0" />
        </el-form-item>
      </el-form>
    </el-card>
  </div>
  <div class="bottom_btn">
    <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
    <el-button @click="$router.go(-1)">取消</el-button>
  </div>
</template>

<script>
export default {
  name: "addGoods",
  data() {
    return {
      id: '',
      is_ajax: false,
      form: {
          
      },
      rules: {
        cargo_space_no: [
          { required: true, message: "货位编号不能为空",trigger: "blur"}
        ],
        cargo_space_name: [
          { required: true, message: "货位名称不能为空",trigger: "blur"}
        ],
        rack_position_number: [
          { required: true, message: "架位号不能为空",trigger: "blur"}
        ]
      }
    };
  },
  created() {
      this.id=this.$route.query.id || '';
      if(this.id){
          this.get_data()
      }
  },
  methods: {
      // 表单重置
      reset(pid) {
        this.form = {
          
        };
        if(this.$refs['form']){
          this.$refs['form'].clearValidate();
        }
      },
      /** 提交按钮 */
      submitForm: function(form) {
        this.$refs[form].validate((valid)  => {
          if (valid) {
            this.is_ajax=true;
            if(form=='form'){
              this.$httpPost("/backend/Warehouse/save", this.form).then((res) => {
                  if (res.status == 200) {
                      if(this.form.id){
                          this.$message.success('更新成功');
                          this.$router.go(-1)
                      }else{
                          this.$message.success('新增成功');
                          // this.reset();
                          this.$router.go(-1)
                      }
                  } else {
                      this.$message.error(res.message);
                  }
                  this.is_ajax=false;
              }).catch((err) => {
                  console.log(err);
                  this.is_ajax=false;
              });
            }
          }
        });
      },
      get_data(){
          this.$httpGet("/backend/Warehouse/read", {id:this.id}).then((res) => {
              if (res.status == 200) {
                  this.form=res.data
              } else {
                  this.$message.error(res.message);
              }
          }).catch((err) => {
              console.log(err);
          });
      },
  }
};
</script>

<style lang="scss" scoped></style>
